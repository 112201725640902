<template>
  <div class="main">
    <div class="top">
      <div class="left">
        <div class="left-top">
          <div class="name" v-if="first_name">{{first_name}} {{ last_name }}</div>
          <div class="name" v-else @click="login()">Login</div>
          <div class="package" v-if="first_name && showPackage">
            <img src="@/assets/me/unlimited.png" alt="" />
          </div>
        </div>
        <div class="left-bottom">
          {{email}}
        </div>
      </div>           
      <div class="right">
        <img src="@/assets/me/default-avatar.png" alt="" v-if="!avatar"/>
        <VueAvatar :username="full_name" backgroundColor="#fff" color="#FF4835"	v-else-if="avatar.indexOf(defaultAvatarUrl) > -1"></VueAvatar>
        <Avatar v-else></Avatar>

      </div>           
    </div>

    <div class="menus">
      <div class="menu" @click="nav('/dashboard')">
        <div class="menu-left">
          <!-- <div class="icon">
            <img src="@/assets/me/user.png" alt="" />
          </div> -->
        <div class="title" >My Profile</div>
        </div>
       
        <div class="continue"> <img src="@/assets/me/next.png" alt="" /></div>
      </div>
      <div class="menu" @click="nav('/my-inquiries')">
        <div class="menu-left">
          <!-- <div class="icon">
            <img src="@/assets/me/product.png" alt="" />
          </div> -->
        <div class="title" >My Products</div>
        </div>
        <div class="continue"> <img src="@/assets/me/next.png" alt="" /></div>
      </div>
      <!-- <div class="menu">
        <div class="menu-left">
          <div class="icon"></div>
        <div class="title">Company profile</div>
        </div>
        <div class="continue"> <img src="@/assets/me/next.png" alt="" /></div>
      </div> -->
    </div>
    <div class="logout" @click="logout()">Logout</div>
    <PopLogin ref="popLogin" @register="register"></PopLogin>
    <PopRegister
      ref="popRegister"
      @successRegister="successRegister"
      @login="loginPopFunc"
    ></PopRegister>
  </div>
</template>

<script>
import api from "@/api";

import store from "@/store";
import { mapState } from "vuex";
import axios from "axios";
import { API_PREFIX } from "@/config";
import {Avatar as VueAvatar}  from 'vue-avatar'
import PopLogin from "@/components/popLogin";
import PopRegister from "@/components/popRegisterEmail";

const {
  _getMyPackage
} = api;

export default {
  name: "me",
  computed: {
    ...mapState("user", ["role","first_name",
      "last_name","email","avatar","full_name"]),
 
  },
  components: { VueAvatar,PopLogin,PopRegister },
  data() {
    return {
      userFullName: '',
      showPackage: false,
      defaultAvatarUrl: '5bEMGyy8RXtwdnCdmgzh19'
    };
  },
  mounted() {
    if(this.email){
        this.userFullName = this.first_name +' '+ this.last_name
        this.getPackage();
    }
  },
  filters: {
    
  },
  methods: {
    getPackage(){
      _getMyPackage()
        .then(res => {
          this.showPackage = res.data;
        })
        .catch(({ message }) => {
          console.log(message);
        })
        .finally(() => {

        });
    },
    login(){
      this.$refs.popLogin.showLogin = true;
    },
    register() {
      this.$refs.popLogin.showLogin = false;
      this.$refs.popRegister.showLogin = true;
    },
    loginPopFunc() {
      this.$refs.popRegister.showLogin = false;
      this.$refs.popLogin.showLogin = true;
    },
    nav(path){
      if ("" === this.role) {
        this.$refs.popLogin.showLogin = true;
        return;
      }
      this.$router.push(path);
    },
    successRegister(token) {
      this.$refs.popRegisterSuccess.showLogin = true;
      this.$refs.popRegisterSuccess.token = token;
    },
    async logout() {
      if ("" === this.role) {
        this.$refs.popLogin.showLogin = true;
        return;
      }
      const msg = this.$Message.loading({
        content: "Logout...",
        duration: 1
      });

      await this.$store.dispatch("user/logout"); 

      this.$router.push(`/`);
    },
  }
};
</script>

<style lang="less" scoped>
  .top{
    background: #120D21;
    height: 130px;
    width: 100%;
    margin-top: 60px;
    display: flex;
    padding-left: 40px;
    padding-right: 40px;
    align-items: center;
    justify-content: space-between;
    .left{
      .left-top{
        display: flex;
        .name{
          font-size: 20px;
          color: #fff;
          font-weight: 700;
        }
        .package{
          margin-left: 4px;
          position: relative;
          top:4px;
          img{
            width: 86px;
            height: 20px;
          }
        }

      }
      .left-bottom{
        font-size: 15px;
        color: #BDBFC7;
        margin-top: 4px
      }
    }
    .right{
     
      img{
        width: 60px;
        height: 60px;
      }

    }
  }
  .menus{
    background: #fff;
    margin-top: 15px;
    .menu{
      height: 42px;
      display: flex;
      justify-content: space-between;
      border-bottom: solid 1px #E3E3E3;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      .menu-left{
        display: flex;
        .icon{
          display:flex;
          align-items: center;
          margin-right: 6px;
          img{
            height: 15px;
            width: auto;
          }
        }
        .title{
          font-size: 15px;
          font-weight: 500;
          color:#272727
        }

      }
      .continue{
        display:flex;
        align-items: center;
        img{
          width: 6.8px;
          height: 11px;  
        }
      }

    }
  }
  .logout{
    height: 45px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    color:#272727;
    margin-top:  25px;
    width: 100%;
  }

  .vue-avatar--wrapper{
    font-size: 16px !important;
    font-weight: 600 !important;
  }

</style>
